import React, {ReactNode, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {getFacilities} from '../api/facility/api'
import {FacilityType} from '../api/facility/types'
import {ProducerType} from '../features/auth/types'
import {APP_ROUTES} from '../router/routes'
import {APP_STATUS} from '../utils/constants'
import {useClient} from './UserContext'

type Props = {
  children: ReactNode;
};

interface ProducerContextInterface {
  producerId: string;
  type: ProducerType;
  facilities: FacilityType[];
  facility: FacilityType;
  setFacility: (facility: FacilityType) => void;
}

const producerContextDefaultValue: ProducerContextInterface = {
  producerId: '',
  type: '' as ProducerType,
  facilities: [],
  facility: {} as FacilityType,
  setFacility: () => {}
}

const ProducerContext = React.createContext<ProducerContextInterface>(
  producerContextDefaultValue
)

function useProducer() {
  const context = React.useContext(ProducerContext)
  if (context === undefined) {
    throw new Error('useProducer must be used within a ProducerProvider')
  }
  return context
}

const ProducerProvider = ({children}: Props) => {
  const navigate = useNavigate()
  const {user} = useClient()
  const [facility, setFacility] = useState<FacilityType>({} as FacilityType)
  const [facilities, setFacilities] = useState<FacilityType[] | null>(null)

  if(!user) navigate(APP_ROUTES.ROOT)

  const producerId = user?.producer.id ?? ''

  useEffect(() => {
    if (user && producerId) {
      getFacilities(producerId)
        .then(response => {
          if(response.status === APP_STATUS.SUCCESS) {
            setFacilities(response.data.facilities)
          }
        })
    }
  }, [user])

  useEffect(() => {
    if (facilities) {
      setFacility(facilities[0])
    }
  }, [facilities])

  if (!facilities) return null

  return (
    <ProducerContext.Provider
      value={{
        producerId,
        type: user?.producer.type ?? ProducerType.CONVERTER,
        facility,
        facilities,
        setFacility
      }}
    >
      {children}
    </ProducerContext.Provider>
  )
}

export {ProducerProvider, useProducer}
