import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {useClient} from '../contexts/UserContext'
import {
  login as loginApi,
  logout as logoutApi,
  validate
} from '../features/auth/api'
import {DetailedUser, LoginData, LoginPayload} from '../features/auth/types'
import {APP_ROUTES} from '../router/routes'
import {getAuthToken, removeAuthToken, setAuthToken} from '../utils/auth'
import {APP_STATUS} from '../utils/constants'
import {getVerificationEventRoute} from '../utils/utils'

export const useAuth = () => {
  const {setIsAuthenticated, setUser} = useClient()
  const [error, setError] = useState<string | null>(null)
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState<boolean>(false)

  const login = async (data: LoginData) => {
    setLoading(true)
    try {
      const payload: LoginPayload = {...data, authOrigin: 'producer'}
      const loginRes = await loginApi(payload)

      if (loginRes.status === APP_STATUS.SUCCESS) {
        await createSession(loginRes.data).then(() =>
          handleRedirect(loginRes.data.user)
        )
      } else if (loginRes.status === APP_STATUS.ERROR) {
        setUser(null)
        setIsAuthenticated(false)
        if (loginRes.error.code) {
          setError(loginRes.error.code)
        } else if (loginRes.error.message) {
          setError(loginRes.error.message)
        } else {
          setError('Unexpected error')
        }
      }
      return loginRes
    } finally {
      setLoading(false)
    }
  }

  const createSession = async (loginRes: {
    token: string;
    user: DetailedUser | null;
  }) => {
    setAuthToken(loginRes.token)
    setIsAuthenticated(true)
    setUser(loginRes.user)
  }

  const handleRedirect = (user: DetailedUser) => {
    const isProducerLinked = Object.keys(user.producer).length > 0
    if (!isProducerLinked) {
      console.error('Producer not linked with user')
      return false
    }

    const {route} = getVerificationEventRoute(user, null)
    if (route) return navigate(route)
  }

  const logout = () => {
    const executeLogoutSequence = () => {
      setUser(null)
      setIsAuthenticated(false)
      removeAuthToken()
      navigate('/')
    }

    return logoutApi()
      .then(() => {
        executeLogoutSequence()
      })
      .catch(() => {
        /**
         * Even though the logout request failed on the API side we can and should
         * still logout the user on the app side.
         * @TODO Log the error on the service level
         */
        executeLogoutSequence()
      })
  }

  const validateUser = () => {
    const navigate = useNavigate()
    const [isAuthenticated, setIsAuthenticated] = useState(
      getAuthToken() ? true : false
    )
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [user, setUser] = useState<DetailedUser | null>(null)

    useEffect(() => {
      async function isAuthTokenValid() {
        const validateResponse = await validate()
        if (validateResponse.status === APP_STATUS.SUCCESS) {
          setIsAuthenticated(true)
          setUser(validateResponse.data.user)
        } else {
          setUser(null)
          setIsAuthenticated(false)
        }
      }
      if (getAuthToken()) {
        isAuthTokenValid().then(() => setIsLoading(false))
      } else {
        setIsLoading(false)
        navigate(APP_ROUTES.ROOT)
      }
    }, [])

    return {
      isAuthenticated,
      setIsAuthenticated,
      user,
      setUser,
      isLoading,
      setIsLoading
    }
  }

  return {login, logout, isLoading, error, validateUser}
}
