import {APP_STATUS} from '../constants'

export type GenericObject = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

export interface LoadingState {
  type: APP_STATUS.LOADING;
}

export interface ErrorState {
  type: APP_STATUS.ERROR;
}

export interface SuccessState {
  type: APP_STATUS.SUCCESS;
}

export interface DataState<T> {
  type: APP_STATUS.DATA;
  data: T;
}

export enum VIEW_TYPE {
  UPLOAD = 'UPLOAD',
  LOADING = 'LOADING',
  REVIEW = 'REVIEW',
  RESULT = 'RESULT'
}

interface UploadViewState {
  type: VIEW_TYPE.UPLOAD;
}

interface LoadingViewState {
  type: VIEW_TYPE.LOADING;
}

interface ReviewViewState {
  type: VIEW_TYPE.REVIEW;
  file: File;
}

interface ResultViewState {
  type: VIEW_TYPE.RESULT;
  success: boolean;
}

export type FileUploadViewState = UploadViewState | LoadingViewState | ReviewViewState | ResultViewState;
