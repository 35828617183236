import {useCallback, useEffect, useState} from 'react'

import {useProducer} from '../../../contexts/ProducerContext'
import {APP_STATUS} from '../../../utils/constants'
import {getFormattedDateString} from '../../../utils/dateTime'
import {DateFormat} from '../../../utils/types/dateTime'
import {FileUploadViewState, VIEW_TYPE} from '../../../utils/types/generics'
import {RenderLoadingView} from '../../dashboard/uploadReport/components/LoadingView'
import {uploadInvoice} from '../api'
import {InvoiceList} from '../mock/invoice-data'
import {InvoiceInfo, InvoiceRequestData} from '../types'
import InvoiceModal from './components/InvoiceModal'
import InvoiceTable from './components/InvoiceTable'
import RevenueChart from './components/RevenueChart'
import {ReviewInvoiceUploadView} from './components/ReviewInvoiceUploadView'
import UploadInvoiceResultView from './components/UploadInvoiceResultView'
import {UploadInvoiceView} from './components/UploadInvoiceView'

const Invoices = () => {
  const {producerId} = useProducer()
  const [fileUploadViewState, setFileUploadViewState] = useState<FileUploadViewState>({type: VIEW_TYPE.UPLOAD})
  const [invoiceInfo, setInvoiceInfo] = useState<InvoiceInfo | null>(null)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const toggleModal = () => setIsModalOpen(!isModalOpen)

  useEffect(() => {
    if (fileUploadViewState.type === VIEW_TYPE.UPLOAD) {
      setIsModalOpen(false)
    }
  }, [fileUploadViewState])

  useEffect(() => {
    if (!isModalOpen) setInvoiceInfo(null)
  }, [isModalOpen])

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const [acceptedFile] = acceptedFiles

    if (acceptedFile) {
      setIsModalOpen(true)
      return setFileUploadViewState({
        type: VIEW_TYPE.REVIEW,
        file: acceptedFile
      })
    }

    return setFileUploadViewState({type: VIEW_TYPE.RESULT, success: false})
  }, [])

  const onSubmit = (selectedDate: Date, file: File) => {
    setFileUploadViewState({type: VIEW_TYPE.LOADING})

    const invoiceRequestData: InvoiceRequestData = {
      uploadedFor: selectedDate
    }
    uploadInvoice(file, invoiceRequestData, producerId)
      .then(response => {
        if (response.status === APP_STATUS.SUCCESS) {
          setInvoiceInfo(response.data)
          setFileUploadViewState({type: VIEW_TYPE.RESULT, success: true})
        } else {
          setFileUploadViewState({type: VIEW_TYPE.RESULT, success: false})
        }
      })
      .catch(() => {
        setFileUploadViewState({type: VIEW_TYPE.RESULT, success: false})
      })
  }

  const isResultView = fileUploadViewState.type === VIEW_TYPE.RESULT
  const isResultViewSuccess = Boolean(isResultView && fileUploadViewState.success && invoiceInfo)
  const resultText = isResultViewSuccess && invoiceInfo ? getFormattedDateString(invoiceInfo.uploadedFor.toString(), DateFormat.MONTH_YEAR) : ''
  const modalTitle = isResultView ? resultText : 'Upload new invoice'

  return (
    <>
      <div className="flex flex-col w-full h-full gap-6 xl:flex-row">
        <div className="w-full xl:w-2/3 min-h-[193px]">
          <RevenueChart />
        </div>
        <div className="w-full xl:w-1/3">
          <UploadInvoiceView onDrop={onDrop} />
        </div>
      </div>
      <div>
        <InvoiceTable
          invoiceInfos={InvoiceList}
          showInvoiceInfo={invoiceInfo => {
            setInvoiceInfo(invoiceInfo)
            setFileUploadViewState({type: VIEW_TYPE.RESULT, success: true})
            toggleModal()
          }}
        />
      </div>
      <InvoiceModal
        title={modalTitle}
        paymentStatus={invoiceInfo?.paymentStatus}
        success={isResultViewSuccess}
        visible={isModalOpen}
        onClose={toggleModal}
      >
        <RenderUploadModalContent
          viewState={fileUploadViewState}
          setViewState={setFileUploadViewState}
          invoiceInfo={invoiceInfo}
          onSubmit={onSubmit}
        />
      </InvoiceModal>
    </>
  )
}

interface RenderUploadModalContentProps {
  viewState: FileUploadViewState;
  setViewState: (viewState: FileUploadViewState) => void;
  invoiceInfo?: InvoiceInfo | null;
  onSubmit: (selectedDate: Date, file: File) => void;
}

const RenderUploadModalContent = (props: RenderUploadModalContentProps) => {
  const {viewState, invoiceInfo, setViewState, onSubmit} = props
  switch (viewState.type) {
    case VIEW_TYPE.REVIEW:
      return (
        <ReviewInvoiceUploadView
          fileName={viewState.file.name}
          defaultSelectedDate={invoiceInfo?.uploadedFor}
          onSubmitClick={selectedDate =>
            onSubmit(selectedDate, viewState.file)
          }
          onRemoveClick={() => {
            setViewState({type: VIEW_TYPE.UPLOAD})
          }}
        />
      )
    case VIEW_TYPE.RESULT:
      return (
        <UploadInvoiceResultView
          invoiceInfo={invoiceInfo}
          success={viewState.success}
          retryAction={() => setViewState({type: VIEW_TYPE.UPLOAD})}
          onReUploadSubmit={onSubmit}
        />
      )
    case VIEW_TYPE.LOADING:
      return <RenderLoadingView />
    default:
      return <span>Invalid state</span>
  }
}

export default Invoices
