import {DefaultPill} from '../../../../components/pills/DefaultPill'
import {SuccessPill} from '../../../../components/pills/SuccessPill'
import {WarningPill} from '../../../../components/pills/WarningPill'
import {PaymentStatus} from '../../types'

export interface PaymentStatusPillProps {
  status: PaymentStatus;
}

export const PaymentStatusPill = ({status}: PaymentStatusPillProps) => {
  switch (status) {
    case PaymentStatus.PAID:
      return <SuccessPill text="Paid" />
    case PaymentStatus.WAITING_FOR_PAYMENT:
      return <DefaultPill text="Waiting for payment" />
    case PaymentStatus.PROVIDE_INVOICE:
      return <WarningPill text="Provide invoice" hasIcon />
    default:
      return null
  }
}
