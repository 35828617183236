import {useNavigate} from 'react-router-dom'

import Image from '../components/image/Image'
import {APP_ROUTES} from '../router/routes'
import {ASSETS} from '../utils/constants'
import WaitList from './WaitList'

interface HeaderProps {
  showWaitList?: boolean;
  portal?: string;
  isExternal?: boolean;
}

const Header = ({showWaitList = false, portal, isExternal = false}: HeaderProps) => {
  const navigate = useNavigate()

  const redirect = () => {
    navigate(APP_ROUTES.ROOT)
  }

  return (
    <nav className="bg-kwota-gray-300 fixed top-0 left-0 right-0 z-50">
      <div className={`${isExternal ? 'max-w-[1340px] lg:px-0' : 'max-w-7xl sm:px-6 lg:px-8'} mx-auto px-4`}>
        <div className={`${isExternal ? 'md:h-16 mt-6 pb-4' : 'md:h-28 py-3 md:py-6'} flex flex-col md:flex-row md:items-end justify-center md:justify-between space-y-3 md:space-y-0`}>
          <div className="flex">
            <div className="flex-shrink-0 flex items-end space-x-2">
              <Image
                onClick={redirect}
                className={`block h-6 ${portal ? 'h-6' : 'h-8'} w-auto cursor-pointer`}
                src={ASSETS.LOGO}
              />
              {portal &&
                <span className="hidden md:block uppercase text-xs text-kwota-subdued font-titillium font-bold">
                  {portal}
                </span>
              }
            </div>
          </div>
          {showWaitList &&
            <div className="hidden md:block">
              <WaitList />
            </div>
          }
        </div>
      </div>
    </nav>
  )
}

export default Header
