import {InvoiceInfo, PaymentStatus, RevenueEarned} from '../types'

export const RevenueEarnedData: RevenueEarned = {
  year: 2019,
  totalEarnedRevenue: 95034.20,
  data: [6000, 5500, 5800, 6800, 7000, 7100, 7000, 6900, 7100, 7200, 7150, 7500]
}

export const InvoiceList: InvoiceInfo[] = [
  {
    id: '1',
    creditsSold: 128.56,
    earnedRevenue: 15403.69,
    fileName: 'file.name1',
    fileOriginalName: 'file.name1',
    filePath: 'file.name1.from.the.producer.pdf',
    paymentStatus: PaymentStatus.PROVIDE_INVOICE,
    uploadedFor: new Date('Tue Nov 01 2022'),
    uploadedOn: new Date('2022-12-01T00:00:00.000Z')
  },
  {
    id: '2',
    creditsSold: 138.56,
    earnedRevenue: 9403.69,
    fileName: 'file.name2',
    fileOriginalName: 'file.name2',
    filePath: 'file.name2.from.the.producer.pdf',
    paymentStatus: PaymentStatus.WAITING_FOR_PAYMENT,
    uploadedFor: new Date('Sat Oct 01 2022'),
    uploadedOn: new Date('2022-11-24T00:00:00.000Z')
  },
  {
    id: '3',
    creditsSold: 118.56,
    earnedRevenue: 12403.69,
    fileName: 'file.name3',
    fileOriginalName: 'file.name3',
    filePath: 'file.name3.from.the.producer.pdf',
    paymentStatus: PaymentStatus.PAID,
    uploadedFor: new Date('Thu Sep 01 2022'),
    uploadedOn: new Date('2022-10-25T00:00:00.000Z')
  },
  {
    id: '4',
    creditsSold: 156,
    earnedRevenue: 15409.69,
    fileName: 'file.name4',
    fileOriginalName: 'file.name4',
    filePath: 'file.name4.from.the.producer.pdf',
    paymentStatus: PaymentStatus.PAID,
    uploadedFor: new Date('Mon Aug 01 2022'),
    uploadedOn: new Date('2022-09-25T00:00:00.000Z')
  },
  {
    id: '5',
    creditsSold: 126,
    earnedRevenue: 14403.69,
    fileName: 'file.name5',
    fileOriginalName: 'file.name5',
    filePath: 'file.name5.from.the.producer.pdf',
    paymentStatus: PaymentStatus.PAID,
    uploadedFor: new Date('Fri Jul 01 2022'),
    uploadedOn: new Date('2022-08-25T00:00:00.000Z')
  }
]
