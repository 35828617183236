import {
  Chart as ChartJS,
  ChartData,
  ChartDataset,
  Filler,
  registerables,
  TooltipItem
} from 'chart.js'
import React, {useEffect, useRef, useState} from 'react'
import {Chart} from 'react-chartjs-2'

import {GenericObject} from '../../utils/types/generics'
import {OverLay} from '../overlay/Overlay'
import {ChartDataProps, TooltipConfig} from './types'
import {
  buildLabel,
  createChartGradient,
  defaultLineChartDataset,
  defaultLineChartOptions,
  defaultTooltipConfig,
  defaultXAxislabels,
  getBorderColor,
  getColorStops
} from './utils'

ChartJS.register(...registerables)

export interface LineChartProps {
  chartData?: ChartDataProps[];
  xAxisLabels?: string[];
  xAxisConfig?: GenericObject;
  yAxisConfig?: GenericObject;
  tooltipConfig?: TooltipConfig;
  overlayText?: string;
  className?: string;
  displayXAxisLabels?: boolean;
}

const defaultText = 'We are waiting for first report'

const LineChart = ({
  chartData,
  xAxisLabels = defaultXAxislabels,
  xAxisConfig,
  yAxisConfig,
  tooltipConfig = defaultTooltipConfig,
  className,
  displayXAxisLabels = true
}: LineChartProps) => {
  const chartRef = useRef<ChartJS>(null)
  const [activeIndex, setActiveIndex] = useState<number>(-1)
  const [chartDatasets, setChartDatasets] = useState<ChartData>({
    labels: xAxisLabels,
    datasets: []
  })

  useEffect(() => {
    const chart = chartRef.current
    const data = chartData?.map(data => {
      const gradient = chart
        ? createChartGradient(
          chart.ctx,
          chart.chartArea,
          getColorStops(data.chartColor)
        )
        : ''

      return {
        ...defaultLineChartDataset,
        label: data.label,
        data: data.data,
        backgroundColor: gradient,
        borderColor: getBorderColor(data.chartColor),
        fill: true
      }
    })
    setChartDatasets({
      ...chartDatasets,
      datasets: data || []
    })
  }, [chartRef])

  const chartTooltip = {
    callbacks: {
      title: (context: { label: any; }[]) => {
        return tooltipConfig.showTitle ? context[0].label : ''
      },
      label: (tooltipItem: TooltipItem<'line'>): string => {
        return `${buildLabel({
          value: tooltipItem.formattedValue,
          prefix: tooltipConfig.prefix,
          suffix: tooltipConfig.suffix
        })}`
      }
    }
  }

  const chartOptions = {
    ...defaultLineChartOptions,
    onHover: (_event: any, chartElements: any[]) => {
      if (chartElements.length) {
        const index = chartElements[0].index
        setActiveIndex(index)
      } else {
        setActiveIndex(-1)
      }
    },
    scales: {
      x: {
        display: displayXAxisLabels,
        ...defaultLineChartOptions.scales.x,
        ticks: {
          ...xAxisConfig?.ticks,
          color: (ticks: any) => {
            if (ticks.index === activeIndex) {
              return '#2A3344'
            } else {
              return '#AFB8C7'
            }
          }
        }
      },
      y: {
        ...defaultLineChartOptions.scales.y,
        ...yAxisConfig
      }
    },
    plugins: {
      tooltip: {
        ...defaultLineChartOptions.plugins.tooltip,
        ...chartTooltip
      }
    }
  }

  const isDatasetsEmpty = (datasets: ChartDataset[]) => {
    return datasets.every(dataset => dataset.data.length === 0)
  }

  return (
    <>
      {isDatasetsEmpty(chartDatasets.datasets) &&
        <OverLay>
          <div>{defaultText}</div>
        </OverLay>
      }
      <Chart
        ref={chartRef}
        type="line"
        id="kwota-line-chart"
        className={className}
        data={chartDatasets}
        onMouseOut={() => setActiveIndex(-1)}
        options={{
          ...chartOptions,
          interaction: {
            mode: 'x'
          },
          responsive: true,
          hover: {
            intersect: false
          },
          plugins: {
            filler: {
              propagate: true
            },
            legend: {
              display: false
            },
            tooltip: {
              ...chartOptions.plugins.tooltip,
              position: 'nearest',
              intersect: false
            }
          }
        }}
        plugins={[Filler]}
      />
    </>
  )
}

export default LineChart
