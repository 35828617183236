import React, {ReactNode, useState} from 'react'

type Props = {
  children: ReactNode;
};

interface ExternalContextInterface {
  showFooter: boolean;
  setShowFooter: (showFooter: boolean) => void;
}

const ExternalContextDefaultValue: ExternalContextInterface = {
  showFooter: false,
  setShowFooter: () => {}
}

const ExternalContext = React.createContext<ExternalContextInterface>(
  ExternalContextDefaultValue
)

function useExternal() {
  const context = React.useContext(ExternalContext)
  if (context === undefined) {
    throw new Error('useExternal must be used within a ExternalProvider')
  }
  return context
}

const ExternalProvider = ({children}: Props) => {

  const [showFooter, setShowFooter] = useState<boolean>(false)

  return (
    <ExternalContext.Provider
      value={{
        showFooter,
        setShowFooter
      }}
    >
      {children}
    </ExternalContext.Provider>
  )
}

export {ExternalProvider, useExternal}
