import {Pill} from '@kwota-cc/shared-components'
import {Icons} from '@kwota-cc/shared-components/dist/components/icons/Icon'

interface DefaultPillProps {
  text?: string;
  icon?: Icons;
}

export const DefaultPill = ({
  text = 'Provide details',
  icon
}: DefaultPillProps) => {
  if (icon) {
    return <Pill text={text} type="neutral" icon={icon} />
  }
  return <Pill text={text} type="neutral" />
}
