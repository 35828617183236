import {API_PATHS} from '../../api/apiRoutes'
import {
  requestWithoutAuthorizationResponseData,
  requestWithoutAuthorizationWithoutResponseData,
  requestWithoutResponseData,
  requestWithResponseData
} from '../../api/fetcher'
import {NoContentResponse} from '../../api/fetcherTypes'
import {DetailedUser, LoginPayload, ResetPasswordRequest} from './types'

export async function login(loginDto: LoginPayload) {
  const url = API_PATHS.USER_LOGIN
  return requestWithoutAuthorizationResponseData<{
    user: DetailedUser;
    token: string;
  }>(url, 'POST', JSON.stringify(loginDto))
}

export async function logout(){
  const url = API_PATHS.USER_LOGOUT
  return requestWithoutResponseData(url, 'POST')
}

export const forgotPassword = (payload: string): Promise<NoContentResponse> =>
  requestWithoutAuthorizationWithoutResponseData('/user/forgot-password', 'POST', payload)

export const validateHash = (hash: string): Promise<NoContentResponse> =>
  requestWithoutAuthorizationWithoutResponseData(`user/validate-hash?hash=${hash}`, 'POST')

export const resetPassword = (payload: ResetPasswordRequest, hash: string): Promise<NoContentResponse> => {
  return requestWithoutAuthorizationWithoutResponseData(`user/reset-password?hash=${hash}`, 'POST', JSON.stringify(payload))
}

export async function validate(){
  const url = API_PATHS.USER_VALIDATE
  return requestWithResponseData<{
    user: DetailedUser;
    token: string;
  }>(url, 'GET')
}
