import {Link} from 'react-router-dom'

import {APP_ROUTES} from '../../router/routes'
import {ASSETS} from '../../utils/constants'
import Image from '../image/Image'

export default function NotFound() {
  return (
    <main className="mx-auto flex h-full min-h-screen w-full max-w-7xl items-center justify-center px-4 sm:px-6 lg:px-8">
      <div className="flex-grow flex-col">
        <div className="flex flex-shrink-0 justify-center">
          <Link to={APP_ROUTES.ROOT} className="inline-flex">
            <span className="sr-only">KWOTA</span>
            <Image
              className="block h-12 w-auto cursor-pointer"
              src={ASSETS.LOGO}
            />
          </Link>
        </div>
        <div className="py-16">
          <div className="text-center">
            <p className="text-base font-semibold text-kwota-blue">404</p>
            <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                Page not found.
            </h1>
            <p className="mt-2 text-base text-gray-500">
                Sorry, we couldn’t find the page you’re looking for.
            </p>
            <div className="mt-6">
              <Link
                to={APP_ROUTES.ROOT}
                className="text-base font-medium text-kwota-blue"
              >
                  Go back home
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
