import {Icon} from '@kwota-cc/shared-components'
import classNames from 'classnames'
import {FileRejection, useDropzone} from 'react-dropzone'

interface UploadFileViewProps {
  onDrop: (acceptedFiles: File[], rejectedFiles: FileRejection[]) => void;
  wide?: boolean;
}

export const UploadFileView = ({onDrop, wide}: UploadFileViewProps) => {
  const dropZoneOptions = {
    onDrop,
    multiple: false,
    accept: {
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    }
  }

  const {getRootProps, getInputProps} = useDropzone(dropZoneOptions)

  const wrapperClassNames = classNames(
    'rounded-lg border-2 border-dashed flex flex-col justify-center items-center cursor-pointer hover:border-[#009FDD] hover:bg-[#009FDD] hover:bg-opacity-5',
    {
      'w-full h-[132px] bg-kwota-neutral-100 px-36 py-8 text-lg border-kwota-primary-500': wide,
      'min-h-[300px] border-[#8996AD]': !wide
    }
  )

  const iconClassNames = classNames({
    'w-[60px]': !wide,
    'w-[30px]': wide
  })

  const titleClassNames = classNames('font-titillium, text-kwota-primary-500 font-semibold', {
    'text-xl': !wide,
    'text-lg': wide
  })

  const textClassNames = classNames('font-normal text-kwota-neutral-500', {
    'text-lg': !wide,
    'text-sm': wide
  })

  return (
    <div
      className={wrapperClassNames}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <div className={iconClassNames}><Icon icon="add-document" size="full" className="fill-[#009FDD]" /></div>
      <div className={titleClassNames}>Upload monthly data</div>
      <div className={textClassNames}>Drop file or click to browse</div>
      <div className={textClassNames}>.xls and .xlsx file</div>
    </div>
  )
}
