import React, {ReactNode} from 'react'

import {DetailedUser} from '../features/auth/types'
import {useAuth} from '../hooks/useAuth'

type Props = {
  children: ReactNode;
};

interface UserContextInterface {
  isAuthenticated: boolean;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  user: DetailedUser | null;
  setUser: (user: DetailedUser | null) => void;
  isLoading: boolean;
  setIsLoading: (isAuthenticated: boolean) => void;
}

const userContextDefaultValue: UserContextInterface = {
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  user: null,
  setUser: () => {},
  isLoading: false,
  setIsLoading: () => {}
}

const UserContext = React.createContext<UserContextInterface>(
  userContextDefaultValue
)

function useClient() {
  const context = React.useContext(UserContext)
  if (context === undefined) {
    throw new Error('useClient must be used within a UserProvider')
  }
  return context
}

const UserProvider = ({children}: Props) => {
  const {validateUser} = useAuth()
  const {
    isAuthenticated,
    setIsAuthenticated,
    user,
    setUser,
    isLoading,
    setIsLoading
  } = validateUser()

  return (
    <UserContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        user,
        setUser,
        isLoading,
        setIsLoading
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export {useClient, UserProvider}
