import {Disclosure, Transition} from '@headlessui/react'
import {Icon, ProgressBar} from '@kwota-cc/shared-components'

import {OnboardingChecklistItem} from '../../../api/producer/types'
import {useOnboardingContext} from '../../../contexts/OnboardingContext'
import {ChecklistItem} from './ChecklistItem'

interface ExpandedChecklistProps {
  toggle: () => void;
}

export const ExpandedChecklist = ({toggle}: ExpandedChecklistProps) => {
  const {checklistItems, onboardingCompleted} = useOnboardingContext()

  if (!checklistItems || checklistItems.length === 0) {
    return null
  }

  const progressBarPercentage = checklistItems.filter(item => item.completed).length / checklistItems.length * 100
  const checklistItemsCompleted = checklistItems.every(item => item.completed) && !onboardingCompleted

  return (
    <div
      style={{background: 'linear-gradient(180deg, #CCEBFC 0%, #FFF 36.68%), #FFF'}}
      className="w-[345px] md:w-[440px] font-titillium flex flex-col py-6 px-4 rounded-2xl shadow-[0_0_24px_0_rgba(0,0,0,0.20)]"
    >
      <div className="px-2">
        <div className="flex justify-between cursor-pointer" onClick={toggle}>
          <div className="text-black text-[28px] font-semibold leading-8">Getting started</div>
          <div className="h-8 w-8">
            <Icon icon="chevron-down" size="full" className="fill-black" />
          </div>
        </div>
        <div className="mt-1.5">
          <span className="text-neutral-600 font-semibold text-sm">Let’s get your producer account set up and going. In order to collaborate with KWOTA please follow the checklist.</span>
        </div>
        <div className="mt-4 space-x-2 flex justify-center items-center">
          <div className="text-xl font-semibold text-neutral-800">{progressBarPercentage}%</div>
          <ProgressBar percentage={progressBarPercentage} greenOnCompleted={true} animate type="secondary" />
        </div>
      </div>
      {checklistItemsCompleted ?
        <CompletedExpandedChecklist checklistItems={checklistItems} />
        :
        <div className="mt-6">
          {checklistItems.map(item =>
            <ChecklistItem
              key={item.id}
              number={item.id}
              text={item.title}
              step={item.step}
              completed={item.completed}
              formId={item.formId}
            />
          )}
        </div>
      }
    </div>
  )
}

interface CompletedExpandedChecklistProps {
  checklistItems: OnboardingChecklistItem[];
}

const CompletedExpandedChecklist = ({checklistItems}: CompletedExpandedChecklistProps) => {
  return (
    <div className="mt-6">
      <div>
        <div className="text-xl text-neutral-800 font-semibold">Congratulations!</div>
        <div className="text-xl text-neutral-800 font-semibold">You've completed all checklist items.</div>
      </div>
      <div className="w-full rounded-lg bg-kwota-primary-100 p-2 flex space-x-2 mt-3 mb-8">
        <div className="flex items-start justify-center">
          <Icon icon="email" className="fill-kwota-primary-700" />
        </div>
        <div className="text-sm font-semibold text-kwota-primary-700">
          You will be notified via email when your additionality assessment has been completed and our cooperation can start.
        </div>
      </div>
      <Disclosure>
        {({open}) =>
          <>
            <Disclosure.Button className="flex justify-between w-full mb-4">
              <div className="text-neutral-800 text-lg">See completed items</div>
              <div className="h-6 w-6">
                <Icon icon={open ? 'chevron-up' : 'chevron-down'} size="full" className="fill-black" />
              </div>
            </Disclosure.Button>
            <Transition
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Disclosure.Panel>
                {checklistItems.map(item =>
                  <ChecklistItem
                    key={item.id}
                    number={item.id}
                    text={item.title}
                    step={item.step}
                    completed={item.completed}
                    formId={item.formId}
                  />
                )}
              </Disclosure.Panel>
            </Transition>
          </>
        }
      </Disclosure>
    </div>
  )
}
