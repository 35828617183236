const Footer = () => {
  return (
    <footer className="bg-kwota-subdued w-full h-[104px] mt-10 bottom-0 absolute">
      <div className="w-full h-full flex items-center justify-center">
        <div className="text-white flex space-x-2 text-lg font-inter">
          <span className="font-normal">Do you have questions for KWOTA?</span>
          <a href="mailto:support@kwota.com" className="font-bold" rel="noreferrer">Contact us</a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
