import {Icon} from '@kwota-cc/shared-components'
import {FileRejection, useDropzone} from 'react-dropzone'

interface UploadInvoiceViewProps {
  onDrop: (acceptedFiles: File[], rejectedFiles: FileRejection[]) => void;
}

export const UploadInvoiceView = ({onDrop}: UploadInvoiceViewProps) => {
  const dropZoneOptions = {
    onDrop,
    multiple: false,
    accept: {
      'application/pdf': ['.pdf']
    }
  }
  const {getRootProps, getInputProps} = useDropzone(dropZoneOptions)

  return (
    <div
      className="min-h-[193px] rounded-xl bg-kwota-neutral-100 border border-dashed border-kwota-primary-500 flex flex-col justify-center items-center cursor-pointer"
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <div className="w-[33px]">
        <Icon
          icon="add-invoice"
          size="full"
          className="fill-kwota-primary-500"
        />
      </div>
      <div className="text-xl font-semibold leading-6 font-titillium text-kwota-primary-500">
        Upload new invoice
      </div>
      <div className="mt-2 max-w-[250px] text-center mx-auto text-lg font-normal leading-6 text-kwota-neutral-500">
        Drop file or click to browse .pdf file
      </div>
    </div>
  )
}
