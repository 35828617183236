import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState
} from 'react'

import {getOnboardingStatus} from '../api/producer/api'
import {
  OnboardingChecklistItem,
  OnboardingStatus
} from '../api/producer/types'
import {useProducer} from './ProducerContext'

interface Props {
  children: ReactElement;
}

interface OnboardingContextProps {
  onboardingCompleted: boolean;
  checklistItems: OnboardingChecklistItem[];
  collapsed: boolean;
  toggleCollapsed: () => void;
  showUploadReportModal: boolean;
  setShowUploadReportModal: (show: boolean) => void;
}

const OnboardingContext = createContext<OnboardingContextProps>({} as OnboardingContextProps)

export const OnboardingContextBase = ({children}: Props) => {
  const {producerId} = useProducer()
  const [onboardingStatus, setOnboardingStatus] = useState<OnboardingStatus>({} as OnboardingStatus)

  const [collapsed, setCollapsed] = useState<boolean>(false)
  const toggleCollapsed = () => setCollapsed(prevCollapsed => !prevCollapsed)

  const [showUploadReportModal, setShowUploadReportModal] = useState<boolean>(false)

  useEffect(() => {
    getOnboardingStatus(producerId).then(response => {
      if (response.status === 'SUCCESS') {
        setOnboardingStatus(response.data)
      }
    })
  }, [producerId])

  return (
    <OnboardingContext.Provider value={{
      onboardingCompleted: onboardingStatus.onboardingCompleted,
      checklistItems: onboardingStatus.checklistItems,
      collapsed,
      toggleCollapsed,
      showUploadReportModal,
      setShowUploadReportModal
    }}>
      {children}
    </OnboardingContext.Provider>
  )
}

export const useOnboardingContext = () => {
  const context = useContext(OnboardingContext)
  if (context === undefined) {
    throw new Error('No OnboardingContextProvider found')
  }

  return context
}
