import {ReactNode} from 'react'

import {useExternal} from '../contexts/ExternalLayoutContext'
import Footer from './Footer'
import Header from './Header'

interface ExternalLayoutProps {
  children: ReactNode;
}

const ExternalLayout = ({children}: ExternalLayoutProps) => {
  const {showFooter} = useExternal()

  return (
    <div className={`flex flex-col min-h-full w-full ${showFooter ? 'h-screen' : 'h-full'} bg-kwota-gray-300 relative`}>
      <Header isExternal={true} />
      <main className="mt-24 max-w-[1340px] mx-auto w-full h-full">
        {children}
      </main>
      {showFooter && <Footer />}
    </div>
  )
}

export default ExternalLayout
