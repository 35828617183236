import {DetailedUser} from '../features/auth/types'
import {APP_ROUTES} from '../router/routes'
import {VERIFICATION_EVENT} from './constants'
import {GenericObject} from './types/generics'

export function getErrorMessage(error: unknown) {
  if (error instanceof Error) {
    return error.message
  }
  return String(error)
}

export const compareString = (value: string, compareValue: string): boolean => {
  const result = value.localeCompare(compareValue, undefined, {sensitivity: 'accent'})
  return result === 0
}

const getProducerVerificationEvent = (user: DetailedUser) => {
  if(user.producer.isVerified) {
    return VERIFICATION_EVENT.VERIFICATION_AGREEMENT_ACCEPTED
  }

  const isProducerVerificationInitiated = user.producer.verificationStepsCompleted?.verification_initiated?.event === VERIFICATION_EVENT.VERIFICATION_INITIATED
  if(isProducerVerificationInitiated) {
    return VERIFICATION_EVENT.VERIFICATION_INITIATED
  }

  return VERIFICATION_EVENT.VERIFICATION_PENDING
}

export const getVerificationEventRoute = (user: DetailedUser, skip: VERIFICATION_EVENT | null = null) => {
  const routeMap = {
    [VERIFICATION_EVENT.VERIFICATION_PENDING]: APP_ROUTES.TERMS_INTRO,
    [VERIFICATION_EVENT.VERIFICATION_INITIATED]: APP_ROUTES.TERMS_INFO,
    [VERIFICATION_EVENT.VERIFICATION_AGREEMENT_ACCEPTED]: APP_ROUTES.DASHBOARD
  }

  const event: VERIFICATION_EVENT = getProducerVerificationEvent(user)

  if(skip) delete routeMap[skip]

  const route = routeMap[event] ?? undefined
  return {route}
}

export const filterList = <T extends GenericObject>(list: T[], filters: { [key: string]: string[]; }): T[] => {
  return list.filter(item => {
    return Object.entries(filters).every(([key, values]) => {
      return values.includes(item[key])
    })
  })
}
