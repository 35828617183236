import {DataState, ErrorState, LoadingState} from '../../utils/types/generics'

export interface RevenueEarned {
  year: number;
  totalEarnedRevenue: number;
  data: number[];
}

export type InvoiceViewState = DataState<RevenueEarned> | LoadingState | ErrorState;

export type FinanceStatus = 'pending' | 'completed'

export enum PaymentStatus {
  PAID = 'Paid',
  WAITING_FOR_PAYMENT = 'Waiting for payment',
  PROVIDE_INVOICE = 'Provide invoice'
}

export interface InvoiceInfo {
  id: string;
  uploadedFor: Date;
  uploadedOn: Date;
  creditsSold: number;
  earnedRevenue: number;
  paymentStatus: PaymentStatus;
  filePath: string;
  fileName: string;
  fileOriginalName: string;
}

export interface InvoiceRequestData {
  uploadedFor: Date;
}
