import {PageHeader, TabBar} from '@kwota-cc/shared-components'
import {Navigate, Outlet, useLocation} from 'react-router-dom'

import {APP_ROUTES} from '../../router/routes'
import {compareString} from '../../utils/utils'

const Invoicing = () => {
  const location = useLocation()
  if (compareString(APP_ROUTES.INVOICING.INDEX, location.pathname)) {
    return <Navigate to={APP_ROUTES.INVOICING.INVOICES} />
  }

  return (
    <div className="space-y-6">
      <PageHeader currentPageTitle="Invoicing" />
      <div className="space-y-8">
        <TabBar
          tabLinks={[
            {title: 'Invoices', path: 'invoices'},
            {title: 'Carbon credit factors', path: 'carbon_credit_factors'}
          ]}
        />
        <Outlet />
      </div>
    </div>
  )
}

export default Invoicing
