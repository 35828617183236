import {Icon} from '@kwota-cc/shared-components'
import {PopupButton} from '@typeform/embed-react'
import classNames from 'classnames'
import {useNavigate} from 'react-router-dom'

import {useOnboardingContext} from '../../../contexts/OnboardingContext'
import {useProducer} from '../../../contexts/ProducerContext'
import {useClient} from '../../../contexts/UserContext'
import {APP_ROUTES} from '../../../router/routes'

interface ChecklistItemProps {
  number: string;
  text: string;
  formId?: string;
  step: string;
  completed?: boolean;
}

export const ChecklistItem = ({number, text, step, formId, completed}: ChecklistItemProps) => {

  const navigate = useNavigate()
  const {setShowUploadReportModal} = useOnboardingContext()
  const {producerId} = useProducer()
  const {user} = useClient()
  if(!user) return null

  const textClasses = classNames('font-normal text-lg', {
    'text-neutral-500 line-through': completed,
    'text-neutral-800': !completed
  })

  const ItemContent = () => {
    const handleRowClick = () => {
      if (step === 'first_batch' && !completed) {
        setShowUploadReportModal(true)
      }
    }

    return (
      <div className="flex items-center justify-between p-2 rounded-md cursor-pointer hover:bg-kwota-neutral-200" onClick={handleRowClick}>
        <div className="flex space-x-4">
          <div className="flex justify-center items-center">
            {completed ? <ChecklistItemCheckIcon /> : <CircledNumber number={number} />}
          </div>
          <div className={textClasses}>{text}</div>
        </div>
        <div className="w-6 h-6">
          <Icon icon="chevron-right" size="full" className="fill-neutral-800" />
        </div>
      </div>
    )
  }

  if (formId && !completed) {
    return (
      <PopupButton
        id={formId}
        style={{fontSize: 20}}
        hidden={{
          producer_id: producerId,
          producer_email: user.email
        }}
        onEndingButtonClick={() => navigate(APP_ROUTES.DASHBOARD)}
        className="text-start w-full"
      >
        <ItemContent />
      </PopupButton>
    )
  } else {
    return <ItemContent />
  }
}

export const CircledNumber = ({number}: {number: string;}) => {
  return (
    <div className="w-7 h-7 rounded-full bg-white border border-neutral-500 flex items-center justify-center">
      <div className="text-neutral-700 font-normal text-sm">{number}</div>
    </div>
  )
}

export const ChecklistItemCheckIcon = () => {
  return (
    <div className="w-7 h-7 rounded-full bg-kwota-primary-400 flex items-center justify-center">
      <Icon icon="tick" size="sm" className="fill-white" />
    </div>
  )
}
