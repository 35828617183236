import '@kwota-cc/shared-components/dist/tailwind.css'
import './assets/styles/app.scss'

import {Toaster} from '@kwota-cc/shared-components'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import React from 'react'
import ReactDOM from 'react-dom/client'
import {BrowserRouter as Router, useRoutes} from 'react-router-dom'

import {router} from './router'

const App = () => {
  return useRoutes(router)
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const queryClient = new QueryClient()

root.render(
  <QueryClientProvider client={queryClient}>
    <Router>
      <Toaster />
      <App />
    </Router>
  </QueryClientProvider>
)
