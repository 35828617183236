import {ChartArea, ChartTypeRegistry, TooltipOptions} from 'chart.js'
import {_DeepPartialObject} from 'chart.js/dist/types/utils'

import {ColorType, CustomLabel, TooltipConfig} from './types'

export const getColorStops = (color?: ColorType): [number, string][] => {
  switch (color) {
    case 'Purple':
    case 'Purple-light':
      return [
        [0, 'rgba(147, 73, 255, 0)'],
        [0.5, 'rgba(147, 73, 255, 0.22)']
      ]
    case 'Green':
      return [
        [0, 'rgba(217, 217, 217, 0)'],
        [0.5, 'rgba(0, 187, 165, 0.22)']
      ]
    case 'Primary':
    case 'Primary-light':
    case 'Neutral':
    default:
      return [
        [0, 'rgba(0, 154, 241, 0)'],
        [0.5, 'rgba(0, 154, 241, 0.22)']
      ]
  }
}

export const getBorderColor = (color?: ColorType): string => {
  switch (color) {
    case 'Purple':
      return '#781BFF'
    case 'Purple-light':
      return '#C9A4FF'
    case 'Green':
      return '#008A7A'
    case 'Primary-light':
      return '#66C3F7'
    case 'Primary':
    case 'Neutral':
    default:
      return '#009AF1'
  }
}

export const defaultXAxislabels = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

interface CreateChartGradient {
  (ctx: CanvasRenderingContext2D, area: ChartArea, colorStops?: [number, string][]): CanvasGradient;
}

const defaultChartColorStops: [number, string][] = [
  [0, 'rgba(0, 154, 241, 0)'],
  [1, 'rgba(0, 154, 241, 0.22)']
]

export const createChartGradient:CreateChartGradient = (ctx, area, colorStops = defaultChartColorStops) => {
  let width, height, gradient: CanvasGradient | undefined
  const chartWidth = area.right - area.left
  const chartHeight = area.bottom - area.top
  if (!gradient || width !== chartWidth || height !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    width = chartWidth
    height = chartHeight
    gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top)

    colorStops.forEach(stop => gradient?.addColorStop(...stop))
  }

  return gradient
}

export const defaultLineChartOptions = {
  responsive: true,
  scales: {
    x: {
      border: {
        display: false
      },
      grid: {
        display: false,
        drawOnChartArea: false,
        drawTicks: false
      }
    },
    y: {
      border: {
        display: false
      },
      grid: {
        drawTicks: false
      }
    }
  },
  plugins: {
    tooltip: {
      enabled: true,
      titleFont: {
        family: 'Inter',
        weight: 'semibold',
        size: 14
      },
      backgroundColor: 'rgba(74, 85, 105, 1)',
      caretSize: 0,
      cornerRadius: 8,
      caretPadding: 15,
      padding: 12,
      displayColors: false,
      usePointStyle: true,
      xAlign: 'center' as const,
      yAlign: 'bottom' as const,
      bodyAlign: 'center' as const
    }
  }
}

export const defaultLineChartDataset = {
  data: [],
  borderWidth: 2,
  pointBorderWidth: 1,
  pointRadius: 8,
  pointHoverRadius: 5,
  pointHoverBorderWidth: 8,
  pointBorderColor: 'rgba(0, 0, 255, 0)',
  pointBackgroundColor: 'rgba(0, 0, 255, 0)',
  pointHoverBackgroundColor: 'rgba(42, 51, 68, 1)',
  pointHoverBorderColor: 'rgba(42, 51, 68, 0.6)',
  tension: 0.5
}

export const buildLabel = ({value, prefix, suffix}: CustomLabel): string | number => {
  if (prefix) {
    return `${prefix} ${value}`
  }

  if (suffix) {
    return `${value} ${suffix}`
  }

  return value
}

export const defaultTooltipOptions: _DeepPartialObject<TooltipOptions<keyof ChartTypeRegistry>> = {
  enabled: true,
  titleFont: {
    family: 'Inter',
    weight: 'semibold',
    size: 14
  },
  backgroundColor: 'rgba(74, 85, 105, 1)',
  caretSize: 0,
  cornerRadius: 8,
  caretPadding: 15,
  padding: 12,
  displayColors: false,
  usePointStyle: true,
  position: 'nearest',
  intersect: false,
  xAlign: 'left' as const,
  yAlign: 'bottom' as const,
  bodyAlign: 'left' as const
}

export const defaultTooltipConfig: TooltipConfig = {
  showTitle: false,
  suffix: '',
  prefix: ''
}
