import {Pill} from '@kwota-cc/shared-components'

interface WarningPillProps {
  text?: string;
  hasIcon?: boolean;
}

export const WarningPill = ({text = 'Provide details', hasIcon = false}: WarningPillProps) => {
  if(hasIcon) {
    return <Pill text={text} type="warning" icon="error" />
  }
  return <Pill text={text} type="warning" />
}
