/**
 * Look pre-defined formats from date-fns documentation:
 * https://date-fns.org/v2.29.3/docs/format
 *
 * Examples:
 * DATE = 10/01/2023
 * DATE_STRING = 10. Feb 2023
 * TIME = 15:30
 * */
export enum DateFormat {
  DATE = 'dd/MM/yyyy',
  DATE_STRING = 'dd. MMM yyyy',
  DATE_STRING_ALT = 'd MMM yyyy',
  DATE_WITH_TIME = 'dd/MM/yyyy HH:mm',
  MONTH_YEAR = 'MMMM yyyy',
  TIME = 'HH:mm'
}

export interface DateRange {
  from: Date;
  to: Date;
}

export enum DateRangeEnum {
  ALL = 'all',
  THIS_WEEK = 'thisWeek',
  THIS_MONTH = 'thisMonth',
  THIS_YEAR = 'thisYear'
}
