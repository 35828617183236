import {ActionButton, Button, Icon} from '@kwota-cc/shared-components'

import {FileUploadViewState, VIEW_TYPE} from '../../../../utils/types/generics'

interface RenderUploadFileViewProps {
  fileName: string;
  onSubmitClick: () => void;
  setViewState: ({type}: FileUploadViewState) => void;
}

export const ReviewFileUploadView = ({fileName, onSubmitClick, setViewState}: RenderUploadFileViewProps) => {
  return (
    <>
      <div className="flex flex-col min-h-[300px]">
        <div className="flex items-center justify-center flex-1">
          <div className="w-full rounded-2xl p-[23px] flex flex-row items-center border border-[#E2E8F0] font-normal text-lg text-[#2A3344]">
            <Icon icon="file-table" size="lg" />
            <div className="flex-1">{fileName}</div>
            <ActionButton icon="recycle-bin" onClick={() => setViewState({type: VIEW_TYPE.UPLOAD})} />
          </div>
        </div>
        <Button
          fullWidth
          text="UPLOAD FILE"
          onClick={onSubmitClick}
        />
      </div>
    </>
  )
}
