import {AnimatedError, AnimatedSuccess, Button} from '@kwota-cc/shared-components'

interface ResultViewProps {
  success: boolean;
  retryAction?: () => void;
}

export const ResultView = ({success, retryAction}: ResultViewProps) => {
  const buildData = () => {
    if (success) {
      return {
        title: 'Thank you!',
        descRowOne: 'Thank you! We have your data and will inspect it in less than 24 hours, we will let you know once the integrity check is done.',
        descRowTwo: false,
        animatedIcon: <AnimatedSuccess />,
        tryAgainButton: null
      }
    }

    return {
      title: 'Error',
      descRowOne: 'We were not able to upload your data.',
      descRowTwo: 'Try again later or upload another file.',
      animatedIcon: <AnimatedError />,
      tryAgainButton: <Button btnType="secondary" text="Try again" onClick={retryAction} />
    }
  }

  const {title, descRowOne, descRowTwo, animatedIcon, tryAgainButton} = buildData()

  return (
    <div className="flex-1 justify-center items-center flex">
      <div className="h-full w-full flex flex-col justify-center items-center text-[#2A3344]">
        <div className="w-32 h-32 mb-[20px]">
          {animatedIcon}
        </div>
        <div className="text-lg font-semibold text-3xl mb-[10px]">{title}</div>
        <div className="text-lg text-base text-center font-inter">
          {descRowOne}
        </div>
        {descRowTwo &&
          <div className="text-lg text-base font-inter">
            {descRowTwo}
          </div>
        }
        {tryAgainButton &&
          <div className="mt-[10px]">{tryAgainButton}</div>
        }
      </div>
    </div>
  )
}
