import {APP_STATUS} from '../../utils/constants'

export interface LoginData {
  username: string;
  password: string;
}

export type LoginPayload = LoginData & {
  authOrigin: string;
}

export interface VerificationInfo {
  id: string;
  producerId: string;
  event: string;
  createdBy: string;
  createdAt: string;
}

export enum ProducerType {
  CONVERTER = 'converter',
  RECYCLER = 'recycler',
}

type producer = {
  id: string;
  name: string;
  type: ProducerType;
  isVerified: boolean;
  verificationStepsCompleted?: {
    verification_initiated?: VerificationInfo;
    verification_agreement_accepted?: VerificationInfo;
  };
}

export interface DetailedUser {
  id: string;
  userName: string;
  firstName: string;
  email: string;
  lastName: string;
  country: string;
  position: string;
  producer: producer;
}

export interface ForgotPasswordFormValue {
  email: string;
}

interface VerificationLoading {
  type: APP_STATUS.LOADING;
}

interface VerificationError {
  type: APP_STATUS.ERROR;
}

interface VerificationSuccess {
  type: APP_STATUS.SUCCESS;
}

export type VerificationViewState = VerificationSuccess | VerificationLoading | VerificationError;

export interface ResetPasswordRequest {
  newPassword: string;
  confirmNewPassword: string;
}
