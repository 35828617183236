import {APP_VARIABLES} from '../utils/constants'

const WaitList = () => {
  return (
    <div className="flex items-center text-right">
      <div className="text-base font-titillium">
        <span className="text-kwota-subdued font-normal">
                  Not our partner yet?
        </span>
        <a
          href={APP_VARIABLES.WAITLIST_LINK}
          target="_blank"
          className="text-kwota-blue ml-2 font-semibold cursor-pointer" rel="noreferrer"
        >
                  Join the waitlist
        </a>
      </div>
    </div>
  )
}

export default WaitList
