import {ReactNode} from 'react'
import {Navigate} from 'react-router-dom'

import {useClient} from '../contexts/UserContext'
import {APP_ROUTES} from '../router/routes'
import Header from './Header'

interface BaseLayoutProps {
  children: ReactNode;
}

const BaseLayout = ({children}: BaseLayoutProps) => {

  const {isAuthenticated, user} = useClient()

  if (isAuthenticated && user?.producer.isVerified) {
    return <Navigate to={APP_ROUTES.DASHBOARD} />
  }

  return (
    <div className="min-h-full w-full h-full bg-kwota-gray-300 relative">
      <Header showWaitList={!isAuthenticated} portal="PRODUCER PORTAL" />
      <main>
        <div className="w-full pb-10">{children}</div>
      </main>
    </div>
  )
}

export default BaseLayout
