import {Button, Icon} from '@kwota-cc/shared-components'
import {useCallback, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {useProducer} from '../../../contexts/ProducerContext'
import {APP_ROUTES} from '../../../router/routes'
import {FILES} from '../../../utils/constants'
import {FileUploadViewState, VIEW_TYPE} from '../../../utils/types/generics'
import {uploadFacilityShipments} from './api'
import {RenderLoadingView} from './components/LoadingView'
import {ResultView} from './components/ResultView'
import {ReviewFileUploadView} from './components/ReviewFileUploadView'
import {UploadFileView} from './components/UploadFileView'

interface FileUploadProps {
  viewState?: FileUploadViewState;
  onClose: () => void;
}

export const UploadReport = ({onClose, viewState = {type: VIEW_TYPE.UPLOAD}}: FileUploadProps) => {

  const navigate = useNavigate()
  const {facility, producerId} = useProducer()
  const [fileUploadViewState, setFileUploadViewState] = useState<FileUploadViewState>(viewState)

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const [acceptedFile] = acceptedFiles

    if(acceptedFile) {
      return setFileUploadViewState({type: VIEW_TYPE.REVIEW, file: acceptedFile})
    }

    return setFileUploadViewState({type: VIEW_TYPE.RESULT, success: false})
  }, [])

  const onSubmit = (file: File) => {
    setFileUploadViewState({type: VIEW_TYPE.LOADING})

    if (!facility) throw Error('Missing facility from the context')
    uploadFacilityShipments(file, facility.id, producerId).then(response => {
      setFileUploadViewState({type: VIEW_TYPE.RESULT, success: response.status === 'OK'})
    })
  }

  const RenderUploadModalContent = () => {
    switch (fileUploadViewState.type) {
      case VIEW_TYPE.UPLOAD:
        return <UploadFileView onDrop={onDrop} wide={false} />
      case VIEW_TYPE.LOADING:
        return <RenderLoadingView />
      case VIEW_TYPE.REVIEW:
        return (
          <ReviewFileUploadView
            onSubmitClick={() => onSubmit(fileUploadViewState.file)}
            fileName={fileUploadViewState.file.name}
            setViewState={setFileUploadViewState}
          />
        )
      case VIEW_TYPE.RESULT:
        return (
          <ResultView
            success={fileUploadViewState.success}
            retryAction={() => setFileUploadViewState({type: VIEW_TYPE.UPLOAD})}
          />
        )
    }
  }

  const closeAndNavigateOnSuccess = () => {
    if (fileUploadViewState.type === VIEW_TYPE.RESULT) {
      navigate(APP_ROUTES.UPLOADS)
      onClose()
    } else {
      onClose()
    }
  }

  return (
    <div className="p-10 bg-white rounded-xl shadow-sm font-titillium w-[550px] min-h-[450px] space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <div className="text-3xl font-semibold">Upload data</div>
        </div>
        <Icon icon="cross" onClick={closeAndNavigateOnSuccess} />
      </div>
      {fileUploadViewState.type === VIEW_TYPE.UPLOAD &&
        <div>
          <a
            href={FILES.CONVERTER_MONTHLY_TEMPLATE}
            target={'_blank'}
            rel="noopener noreferrer"
          >
            <Button text="Download monthly data template" btnType="secondary" size="md" rightIcon="download" />
          </a>
        </div>
      }
      <RenderUploadModalContent />
    </div>
  )
}
