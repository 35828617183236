import {Icon} from '@kwota-cc/shared-components'

interface CollapsedChecklistProps {
  toggle: () => void;
}

export const CollapsedChecklist = ({toggle}: CollapsedChecklistProps) => {
  return (
    <div className="w-[345px] md:w-[440px] font-titillium py-6 px-4 rounded-2xl bg-gradient-to-b from-0% from-kwota-primary to-100% to-kwota-primary-300 shadow-[0_0_24px_0_rgba(0,0,0,0.20)]">
      <div className="flex justify-between cursor-pointer" onClick={toggle}>
        <div className="text-white text-[28px] font-semibold leading-8">Getting started</div>
        <div className="h-8 w-8">
          <Icon icon="chevron-up" size="full" className="fill-white" />
        </div>
      </div>
    </div>
  )
}
