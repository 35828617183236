import {
  ActionButton,
  Button,
  Dropdown,
  Icon
} from '@kwota-cc/shared-components'
import {DropdownOption} from '@kwota-cc/shared-components/dist/components/dropdown/Dropdown'
import {useState} from 'react'

import {getDateListFrom} from '../../../../utils/dateTime'

interface RenderUploadInvoiceViewProps {
  fileName: string;
  defaultSelectedDate?: Date;
  onSubmitClick: (selectedDate: Date) => void;
  onRemoveClick: () => void;
}

export const ReviewInvoiceUploadView = ({
  fileName,
  defaultSelectedDate,
  onSubmitClick,
  onRemoveClick
}: RenderUploadInvoiceViewProps) => {
  const months: DropdownOption[] = getDateListFrom().map(data => {
    return {
      id: data.firstDayOfMonth.toString(),
      value: `${data.month} ${data.year}`
    }
  })

  const [selectedMonth, setSelectedMonth] = useState(months[0])

  const handleOnClick = () => {
    let selectedDate = new Date(selectedMonth.id)
    if (defaultSelectedDate) {
      selectedDate = defaultSelectedDate
    }
    onSubmitClick(selectedDate)
  }

  return (
    <>
      <div className={`${defaultSelectedDate ? 'space-y-6' : 'space-y-10'} flex flex-col`}>
        <div className="flex flex-col items-center justify-center flex-1 space-y-8">
          {!defaultSelectedDate &&
            <div className="w-full space-y-2">
              <label className="text-[18px] leading-6 font-titillium text-kwota-neutral-800">
                Uploading invoice for
              </label>
              <Dropdown
                options={months}
                selectedValue={selectedMonth}
                setSelectedValue={setSelectedMonth}
              />
            </div>
          }
          <div className="flex flex-row items-center w-full p-4 border rounded-2xl border-kwota-neutral-300">
            <Icon icon="pdf" size="lg" className="fill-kwota-neutral-800" />
            <div className="flex-1 text-lg font-normal leading-6 font-titillium text-kwota-neutral-800">
              {fileName}
            </div>
            <ActionButton icon="recycle-bin" onClick={onRemoveClick} />
          </div>
        </div>
        <div className="flex justify-center">
          <Button
            fullWidth
            size="lg"
            text="Upload invoice"
            onClick={handleOnClick}
          />
        </div>
      </div>
    </>
  )
}
