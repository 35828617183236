import {Pill} from '@kwota-cc/shared-components'

interface SuccessPillProps {
  text?: string;
  hasIcon?: boolean;
}

export const SuccessPill = ({text = 'Success', hasIcon = false}: SuccessPillProps) => {
  if(hasIcon) {
    return <Pill text={text} type="green" icon="tick" />
  }
  return <Pill text={text} type="green" />
}
