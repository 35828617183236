import {lazy, Suspense} from 'react'
import {Outlet, RouteObject} from 'react-router-dom'

import NotFound from '../components/errors/NotFound'
import Loading from '../components/loading/Loading'
import {ExternalProvider} from '../contexts/ExternalLayoutContext'
import {OnboardingContextBase} from '../contexts/OnboardingContext'
import {ProducerProvider} from '../contexts/ProducerContext'
import {UserProvider} from '../contexts/UserContext'
import CarbonCreditFactors from '../features/invoicing/carbon-credit-factors/CarbonCreditFactors'
import Invoices from '../features/invoicing/invoices/Invoices'
import Invoicing from '../features/invoicing/Invoicing'
import BaseLayout from '../layouts/BaseLayout'
import CleanLayout from '../layouts/cleanLayout/CleanLayout'
import DashboardLayout from '../layouts/DashboardLayout'
import EmptyLayout from '../layouts/EmptyLayout'
import ExternalLayout from '../layouts/ExternalLayout'
import LayoutView from '../layouts/LayoutView'
import {APP_ROUTES} from './routes'

const SuspenseLoader = (Component: React.ComponentType) => () =>

  <Suspense fallback={<Loading />}>
    <Component />
  </Suspense>

const Login = SuspenseLoader(lazy(() => import('../features/auth/login/Login')))
const ForgotPassword = SuspenseLoader(lazy(() => import('../features/auth/forgotPassword/ForgotPassword')))
const ResetPassword = SuspenseLoader(lazy(() => import('../features/auth/resetPassword/ResetPassword')))
const Intro = SuspenseLoader(lazy(() => import('../features/terms/termsIntro/TermsIntro')))
const Info = SuspenseLoader(lazy(() => import('../features/terms/termsInfo/TermsInfo')))
const Dashboard = SuspenseLoader(lazy(() => import('../features/dashboard/Dashboard')))
const Uploads = SuspenseLoader(lazy(() => import('../features/uploads/Uploads')))
const Contracts = SuspenseLoader(lazy(() => import('../features/contracts/Contracts')))
const Finances = SuspenseLoader(lazy(() => import('../features/finances/Finances')))
const Partners = SuspenseLoader(lazy(() => import('../features/partners/Partners')))
const Settings = SuspenseLoader(lazy(() => import('../features/settings/SettingsRoot')))
const Profile = SuspenseLoader(lazy(() => import('../features/settings/profile/Profile')))
const General = SuspenseLoader(lazy(() => import('../features/settings/general/General')))
const Verification = SuspenseLoader(lazy(() => import('../features/verification/Verification')))

const PartnerVerification = SuspenseLoader(lazy(() => import('../features/partner-verification/PartnerVerification')))
const Confirmed = SuspenseLoader(lazy(() => import('../features/partner-verification/Confirmed')))

const BaseLayoutView = () => {

  return (
    <UserProvider>
      <BaseLayout>
        <Outlet />
      </BaseLayout>
    </UserProvider>
  )
}

const DashboardView = () => {
  return (
    <UserProvider>
      <ProducerProvider>
        <OnboardingContextBase>
          <DashboardLayout>
            <Outlet />
          </DashboardLayout>
        </OnboardingContextBase>
      </ProducerProvider>
    </UserProvider>
  )
}

const ExternalLayoutView = () => {
  return (
    <ExternalProvider>
      <ExternalLayout>
        <Outlet />
      </ExternalLayout>
    </ExternalProvider>
  )
}

const EmptyLayoutView = () => {
  return (
    <EmptyLayout>
      <Outlet />
    </EmptyLayout>
  )
}

const publicRoutes: RouteObject[] = [
  {
    element: <LayoutView Component={CleanLayout} />,
    children: [
      {path: APP_ROUTES.ROOT, element: <Login />},
      {path: APP_ROUTES.LOGIN, element: <Login />},
      {path: APP_ROUTES.FORGOT_PASSWORD, element: <ForgotPassword />},
      {path: APP_ROUTES.RESET_PASSWORD, element: <ResetPassword />}
    ]
  }
]

const privateRoutesWithBaseLayout: RouteObject[] = [
  {
    element: <BaseLayoutView />,
    children: [
      {path: APP_ROUTES.TERMS, element: <Intro />},
      {path: APP_ROUTES.TERMS_INTRO, element: <Intro />},
      {path: APP_ROUTES.TERMS_INFO, element: <Info />}
    ]
  }
]

const privateRoutes: RouteObject[] = [
  {
    element: <DashboardView />,
    children: [
      {path: APP_ROUTES.DASHBOARD, element: <Dashboard />},
      {path: APP_ROUTES.UPLOADS, element: <Uploads />},
      {path: APP_ROUTES.VERIFICATION, element: <Verification />},
      {path: APP_ROUTES.PARTNER, element: <Partners />},
      {path: APP_ROUTES.CONTRACT, element: <Contracts />},
      {path: APP_ROUTES.FINANCES, element: <Finances />},
      {path: APP_ROUTES.INVOICING.INDEX, element: <Invoicing />, children: [
        {path: APP_ROUTES.INVOICING.INVOICES, element: <Invoices />},
        {path: APP_ROUTES.INVOICING.CARBON_CREDIT_FACTORS, element: <CarbonCreditFactors />}
      ]},
      {path: APP_ROUTES.SETTINGS.INDEX, element: <Settings />, children: [
        {path: APP_ROUTES.SETTINGS.PROFILE, element: <Profile />},
        {path: APP_ROUTES.SETTINGS.GENERAL, element: <General />}
      ]}
    ]
  }
]

const externalRoutes: RouteObject[] = [
  {
    element: <ExternalLayoutView />,
    children: [
      {path: APP_ROUTES.SHIPMENT_INCONSISTENCIES, element: <PartnerVerification />},
      {path: APP_ROUTES.SHIPMENT_CONFIRMED, element: <Confirmed />}
    ]
  }
]

const appRoutes: RouteObject[] = [
  {
    element: <EmptyLayoutView />,
    children: [
      {path: APP_ROUTES.INVALID, element: <NotFound />},
      {path: APP_ROUTES.NOT_FOUND, element: <NotFound />}
    ]
  }
]

export const router: RouteObject[] = [
  ...publicRoutes,
  ...privateRoutesWithBaseLayout,
  ...privateRoutes,
  ...externalRoutes,
  ...appRoutes
]
