import {Icon, showToast, Toaster} from '@kwota-cc/shared-components'
import React from 'react'

import {BILLING_INFO} from '../../../../utils/constants'
import {calculateVAT} from '../../utils'

interface InvoicePropertiesProps {
  amount: number;
  description: string;
}

const InvoiceProperties = ({amount, description}: InvoicePropertiesProps) => {
  const handleCopyClick = (text: string) => {
    navigator.clipboard.writeText(text)
    showToast({
      type: 'success',
      message: 'Text copied'
    })
  }

  const vatAmount = Number(calculateVAT(amount).toFixed(2))
  const amountWithVAT = Number((amount + vatAmount).toFixed(2))

  return (
    <>
      <div className="space-y-6 transition-all duration-500 ease-in-out">
        <div className="space-y-4">
          <div className="space-y-2">
            <label className="text-lg leading-6 font-titillium text-kwota-neutral-800">
              To:
            </label>
            <div className="w-full sm:w-[488px] flex flex-col sm:flex-row items-center justify-between p-3 sm:space-x-16 rounded-lg bg-kwota-neutral-200">
              <p className="text-lg leading-6 font-titillium text-kwota-neutral-800">
                {BILLING_INFO.BILLING_ADDRESS}
              </p>
              <div className="flex justify-end w-full sm:w-min">
                <div
                  onClick={() => handleCopyClick(BILLING_INFO.BILLING_ADDRESS)}
                  className="flex items-center justify-center p-2 border rounded-lg cursor-pointer bg-kwota-neutral-100 border-kwota-neutral-300"
                >
                  <Icon icon="paper" className="fill-kwota-primary-500" />
                  <div className="text-sm font-semibold text-kwota-primary-500 font-titillium">
                    Copy
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-2">
            <label className="text-lg leading-6 font-titillium text-kwota-neutral-800">
              Description:
            </label>
            <div className="w-full sm:w-[488px] flex flex-col sm:flex-row items-center justify-between p-3 sm:space-x-16 rounded-lg bg-kwota-neutral-200">
              <p className="text-lg leading-6 font-titillium text-kwota-neutral-800">
                {description}
              </p>
              <div className="flex justify-end w-full sm:w-min">
                <div
                  onClick={() => handleCopyClick(description)}
                  className="flex items-center justify-center p-2 border rounded-lg cursor-pointer bg-kwota-neutral-100 border-kwota-neutral-300"
                >
                  <Icon icon="paper" className="fill-kwota-primary-500" />
                  <div className="text-sm font-semibold text-kwota-primary-500 font-titillium">
                    Copy
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <p className="text-lg leading-6 font-titillium text-kwota-neutral-800">
              Due date:
            </p>
            <p className="text-lg leading-6 font-titillium text-kwota-neutral-800">
              30 days
            </p>
          </div>
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <p className="text-lg leading-6 font-titillium text-kwota-neutral-800">
                Invoice w/o VAT:
              </p>
              <p className="text-lg leading-6 font-titillium text-kwota-neutral-800">
                {`${amount}€`}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <p className="text-lg leading-6 font-titillium text-kwota-neutral-800">
                VAT 20%:
              </p>
              <p className="text-lg leading-6 font-titillium text-kwota-neutral-800">
                {`${vatAmount}€`}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <p className="text-lg leading-6 font-titillium text-kwota-neutral-800">
                Invoice incl VAT:
              </p>
              <p className="text-xl font-semibold leading-6 font-titillium text-kwota-primary-500">
                {`${amountWithVAT}€`}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </>
  )
}

export default InvoiceProperties
