import ConverterMonthlyDataTemplate from '../assets/files/converter_monthly_data_template.xlsx'
import PartnerEmail from '../assets/files/partner_email.pdf'
import RecyclerMonthlyDataTemplate from '../assets/files/recycler_monthly_data_template.xlsx'
import Forbidden from '../assets/images/403.svg'
import LoginBanner from '../assets/images/kwota-icebergs-placeholder.jpg'
import LoginBannerSm from '../assets/images/kwota-icebergs-placeholder-sm.jpg'
import Lock from '../assets/images/lock.svg'
import logo from '../assets/images/logo.svg'
import NoImageFound from '../assets/images/no-image-found.png'
import TermsIntroBanner from '../assets/images/signing-terms-of-services.png'
import TermsIntro from '../assets/images/signing-terms-of-services.svg'

export const ASSETS = {
  FORBIDDEN: Forbidden,
  LOCK: Lock,
  LOGO: logo,
  LOGIN_BANNER: LoginBanner,
  LOGIN_BANNER_SM: LoginBannerSm,
  LOGIN_BANNER_PLACEHOLDER: LoginBanner,
  NO_IMAGE_FOUND: NoImageFound,
  TERMS_INTRO: TermsIntro,
  TERMS_INTRO_PLACEHOLDER: TermsIntroBanner
}

export const FILES = {
  PARTNER_EMAIL: PartnerEmail,
  CONVERTER_MONTHLY_TEMPLATE: ConverterMonthlyDataTemplate,
  RECYCLER_MONTHLY_TEMPLATE: RecyclerMonthlyDataTemplate
}

export const APP_VARIABLES = {
  LOCAL_STORAGE_KEY: '__auth_provider_token__',
  AUTH_ORIGIN: 'producer',
  WAITLIST_LINK: 'https://docs.google.com/forms/d/e/1FAIpQLSdIcM-4UGXRDERNJSInac_f60XOrPMvFEZsFdF2B1NZuR-P1A/viewform'
}

export enum APP_STATUS {
  CONFIRMED = 'CONFIRMED',
  DATA = 'DATA',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
  OK = 'OK'
}

export enum APP_ERRORS {
  INVALID_TOKEN = 'Invalid token',
  AUTHENTICATION_FAILED = 'Authentication failed'
}

export enum VERIFICATION_EVENT {
  VERIFICATION_PENDING = 'verification_pending',
  VERIFICATION_INITIATED = 'verification_initiated',
  VERIFICATION_AGREEMENT_ACCEPTED = 'verification_agreement_accepted',
}

export const BILLING_INFO = {
  VAT: 20,
  BILLING_ADDRESS: 'KWOTA OÜ, Pärnu mnt 141, 11314 Tallinn, reg.nr: 16253795, KMKR: EE102382985',
  EMAIL_ADDRESS: 'invoices@kwota.com'
}
