import {ActionButton, Table} from '@kwota-cc/shared-components'
import {TableCells, TableHeaders, TableRows} from '@kwota-cc/shared-components/dist/components/tables/types'
import {ReactNode} from 'react'

import {getFormattedDateString} from '../../../../utils/dateTime'
import {DateFormat} from '../../../../utils/types/dateTime'
import {InvoiceInfo, PaymentStatus} from '../../types'
import {PaymentStatusPill} from './PaymentStatusPill'

interface InvoiceTableProps {
  invoiceInfos: InvoiceInfo[];
  showInvoiceInfo: (invoiceInfo: InvoiceInfo) => void;
}

const InvoiceTable = ({invoiceInfos, showInvoiceInfo}: InvoiceTableProps) => {
  const tableHeaders: TableHeaders[] = [
    {id: 'period', name: 'Period'},
    {id: 'amount', name: 'Amount'},
    {id: 'status', name: 'Status'},
    {id: 'last_status_change', name: 'Last status change'},
    {id: 'action', name: ''}
  ]

  const handleRowClick = (id: string) => {
    const data = invoiceInfos.find(info => info.id === id)
    if (data) {
      showInvoiceInfo(data)
    }
  }

  const handleOnClickDownload = (id: string) => {
    console.log('implement download functionality: ', id )
  }

  const tableBody = (invoiceInfos: InvoiceInfo[]): TableRows[] => {
    return invoiceInfos.map(invoiceInfo => {
      const getColumnId = (identifier: string) => `${identifier}_${invoiceInfo.id}`
      const isDownloadable = invoiceInfo.paymentStatus !== PaymentStatus.PROVIDE_INVOICE

      const tableCells: TableCells[] = [
        {
          id: getColumnId('period'),
          content:
            <ClickableRow
              content={getFormattedDateString(
                invoiceInfo.uploadedFor.toString(),
                DateFormat.MONTH_YEAR
              )}
              id={invoiceInfo.id}
              onClick={handleRowClick}
            />
        },
        {
          id: getColumnId('amount'),
          content:
            <ClickableRow
              content={`${invoiceInfo.earnedRevenue}€`}
              id={invoiceInfo.id}
              onClick={handleRowClick}
            />
        },
        {
          id: getColumnId('status'),
          content:
            <ClickableRow
              content={<PaymentStatusPill status={invoiceInfo.paymentStatus} />}
              id={invoiceInfo.id}
              onClick={handleRowClick}
            />
        },
        {
          id: getColumnId('last_status_change'),
          content:
            <ClickableRow
              content={getFormattedDateString(
                invoiceInfo.uploadedOn.toString(),
                DateFormat.DATE
              )}
              id={invoiceInfo.id}
              onClick={handleRowClick}
            />
        },
        {
          id: getColumnId('action'),
          content:
            <InvoiceActionButton
              id={invoiceInfo.id}
              isDownload={isDownloadable}
              onClickUpload={handleRowClick}
              onClickDownload={handleOnClickDownload}
            />
        }
      ]
      return {
        id: invoiceInfo.id,
        content: tableCells
      }
    })
  }

  return (
    <>
      <Table
        headers={tableHeaders}
        content={tableBody(invoiceInfos)}
        type="data"
      />
    </>
  )
}

interface ClickableRowProps {
  id: string;
  content?: string | ReactNode;
  onClick: (id: string) => void;
}

const ClickableRow = ({content, id, onClick}: ClickableRowProps) => {
  return (
    <div className="cursor-pointer" onClick={() => onClick(id)}>
      {content}
    </div>
  )
}

interface InvoiceActionButtonProps {
  id: string;
  isDownload: boolean;
  onClickDownload?: (id: string) => void;
  onClickUpload?: (id: string) => void;
}

const InvoiceActionButton = ({
  id,
  isDownload,
  onClickDownload,
  onClickUpload
}: InvoiceActionButtonProps) => {
  if (isDownload) {
    return <ActionButton icon="download" onClick={() => onClickDownload?.(id)} />
  }

  return <ActionButton icon="upload" onClick={() => onClickUpload?.(id)} />
}

export default InvoiceTable
