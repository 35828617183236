import {DropdownOption} from '@kwota-cc/shared-components/dist/components/dropdown/Dropdown'

import {FacilityType} from '../api/facility/types'

export function mapFacilityToOption(facility: FacilityType): DropdownOption {
  return {
    id: facility.id,
    value: facility.name
  }
}

export function mapFacilitiesToOptions(facilities: FacilityType[]): DropdownOption[] {
  return facilities.map(data => {
    return mapFacilityToOption(data)
  })
}

export function mapOptionToFacility(option: DropdownOption): FacilityType {
  return {
    id: option.id,
    name: option.value
  }
}
