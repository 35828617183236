import {SidebarMenuItems} from '@kwota-cc/shared-components/dist/components/layout/types'

export const APP_ROUTES = {
  //Public routes
  ROOT: '/',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:hash',

  // Private routes
  TERMS: '/terms-and-condition',
  TERMS_INTRO: '/terms-and-condition/intro',
  TERMS_INFO: '/terms-and-condition/info',
  DASHBOARD: '/dashboard',
  UPLOADS: '/uploads',
  CONTRACT: '/contracts',
  VERIFICATION: '/verification',
  PARTNER: '/partners',
  FINANCES: '/finances',
  INVOICING: {
    INDEX: '/invoicing',
    INVOICES: '/invoicing/invoices',
    CARBON_CREDIT_FACTORS: '/invoicing/carbon_credit_factors'
  },
  SETTINGS: {
    INDEX: '/settings',
    PROFILE: '/settings/profile',
    GENERAL: '/settings/general'
  },

  // External routes
  SHIPMENT_INCONSISTENCIES: '/verification/inconsistencies/:hash',
  SHIPMENT_CONFIRMED: '/verification/confirmed/:hash',

  //Invalid or Error routes
  INVALID: '*',
  NOT_FOUND: '/404',
  NOT_AUTHORIZED: '/403'
}

export const NAV_LINKS: SidebarMenuItems[] = [
  {
    name: 'Dashboard',
    icon: 'columns-outline',
    url: APP_ROUTES.DASHBOARD,
    hidden: false
  },
  {
    name: 'Data uploads',
    icon: 'upload',
    url: APP_ROUTES.UPLOADS,
    hidden: false
  },
  {
    name: 'Verification',
    icon: 'verification',
    url: APP_ROUTES.VERIFICATION,
    hidden: false
  },
  {
    name: 'Partners',
    icon: 'truck',
    url: APP_ROUTES.PARTNER,
    hidden: false
  },
  {
    name: 'Finances',
    icon: 'money',
    url: APP_ROUTES.FINANCES,
    hidden: false
  },
  {
    name: 'Invoicing',
    icon: 'invoice',
    url: APP_ROUTES.INVOICING.INDEX,
    hidden: process.env.REACT_APP_ENV === 'production' /** @TODO set to false when this functionality is ready for prod */
  },
  {
    name: 'Files',
    icon: 'paper',
    url: APP_ROUTES.CONTRACT,
    hidden: false
  },
  {
    name: 'Settings',
    icon: 'settings',
    url: APP_ROUTES.SETTINGS.PROFILE,
    hidden: false
  }
]
