import {DashboardLayout as SharedDashboardLayout, Modal} from '@kwota-cc/shared-components'
import {DropdownOption} from '@kwota-cc/shared-components/dist/components/dropdown/Dropdown'
import {ReactNode} from 'react'
import {Navigate} from 'react-router-dom'

import {Checklist} from '../components/checklist/Checklist'
import {useOnboardingContext} from '../contexts/OnboardingContext'
import {useProducer} from '../contexts/ProducerContext'
import {useClient} from '../contexts/UserContext'
import {UploadReport} from '../features/dashboard/uploadReport/UploadReport'
import {useAuth} from '../hooks/useAuth'
import {APP_ROUTES, NAV_LINKS} from '../router/routes'
import {
  mapFacilitiesToOptions,
  mapFacilityToOption,
  mapOptionToFacility
} from '../utils/facility'

interface DashboardLayoutProps {
  children: ReactNode;
}

const DashboardLayout = ({children}: DashboardLayoutProps) => {
  const {isAuthenticated, user, isLoading} = useClient()
  const {facility, facilities, setFacility} = useProducer()
  const {onboardingCompleted} = useOnboardingContext()
  const {showUploadReportModal, setShowUploadReportModal} = useOnboardingContext()
  const closeUploadReportModal = () => setShowUploadReportModal(false)

  const {logout} = useAuth()

  if (isLoading) {
    return null
  }

  if (!isAuthenticated || !user?.producer.isVerified) {
    return <Navigate to={APP_ROUTES.ROOT} />
  }

  const handleOnSelectOption = (selected: DropdownOption) => {
    setFacility(mapOptionToFacility(selected))
  }

  const dropdownOption = {
    options: mapFacilitiesToOptions(facilities),
    selectedOption: facility ? mapFacilityToOption(facility) : mapFacilitiesToOptions(facilities)[0],
    onSelect: handleOnSelectOption
  }

  return (
    <>
      <SharedDashboardLayout
        currentMode="producer portal"
        organization={{name: user.producer.name}}
        menuItems={NAV_LINKS}
        onLogout={logout}
        children={children}
        dropdownOption={dropdownOption}
      />
      {!onboardingCompleted &&
        <div className="fixed bottom-10 md:right-8 right-4">
          <Checklist />
        </div>
      }
      <Modal
        visible={showUploadReportModal}
        onClose={closeUploadReportModal}
      >
        <UploadReport onClose={closeUploadReportModal} />
      </Modal>
    </>
  )
}

export default DashboardLayout
