import {ReactNode} from 'react'

interface OverLayProps {
  className?: string;
  text?: string;
  children?: ReactNode;
}

export const OverLay = ({className, children}: OverLayProps) =>
  <div id="overlay" style={{backgroundColor: 'rgba(255, 255, 255, 0.8)'}} className={`absolute top-0 left-0 right-0 bottom-0 z-10 flex items-center justify-center ${className && className}`}>
    {children}
  </div>
