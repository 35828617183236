import {DataResponse} from '../../api/fetcherTypes'
import {APP_STATUS} from '../../utils/constants'
import {RevenueEarnedData} from './mock/invoice-data'
import {InvoiceRequestData, PaymentStatus, RevenueEarned} from './types'

export const getEarnedRevenueData = async (): Promise<DataResponse<RevenueEarned>> => {
  return {
    status: APP_STATUS.SUCCESS,
    data: RevenueEarnedData
  }
}

export const uploadInvoice = async (file: File, invoiceRequestData: InvoiceRequestData, producerId: string) => {
  // const url = API_PATHS.INVOICE_UPLOAD.replace(':id', producerId)
  // return requestWithFileWithResponseData<InvoiceInfo>(url, 'POST', file, JSON.stringify(invoiceRequestData))
  /** @TODO uncomment above lines and delete below return code */
  return {
    status: APP_STATUS.SUCCESS,
    data: {
      id: producerId,
      creditsSold: 128.56,
      earnedRevenue: 15403.69,
      fileName: file.name,
      fileOriginalName: file.name,
      filePath: 'file.name.from.the.producer.pdf',
      paymentStatus: PaymentStatus.WAITING_FOR_PAYMENT,
      uploadedFor: invoiceRequestData.uploadedFor,
      uploadedOn: new Date()
    }
  }
}
