import {Spinner} from '@kwota-cc/shared-components'
import React, {useEffect, useState} from 'react'

import LineChart from '../../../../components/charts/LineChart'
import {ChartDataProps} from '../../../../components/charts/types'
import {buildLabel, defaultXAxislabels} from '../../../../components/charts/utils'
import InternalServerError from '../../../../components/errors/InternalServerError'
import {APP_STATUS} from '../../../../utils/constants'
import {getEarnedRevenueData} from '../../api'
import {InvoiceViewState, RevenueEarned} from '../../types'

const RevenueChart = () => {
  const [revenueChartViewState, setRevenueChartViewState] = useState<InvoiceViewState>({type: APP_STATUS.LOADING})

  useEffect(() => {
    fetchRevenueEarned()
  }, [])

  const fetchRevenueEarned = () => {
    getEarnedRevenueData().then(response => {
      if(response.status === APP_STATUS.SUCCESS) {
        setRevenueChartViewState({
          type: APP_STATUS.DATA,
          data: response.data
        })
      }
    })
  }

  return <PageContent viewState={revenueChartViewState} onClickTryAgain={() => fetchRevenueEarned()} />
}

const RevenueChartContainer = ({revenueChartData}: {revenueChartData: RevenueEarned;}) => {
  const chartData: ChartDataProps[] = [
    {
      label: 'Total earned revenue',
      data: revenueChartData.data,
      chartColor: 'Green'
    }
  ]

  const xAxis = {
    ticks: {
      color: '#818DA1',
      font: {
        size: 14,
        weight: '600',
        lineHeight: '20px',
        family: 'Titillium Web'
      },
      callback: (i: number) => i % 2 ? '' : defaultXAxislabels[i]
    }
  }

  const yAxis = {
    position: 'right',
    ticks: {
      color: '#818DA1',
      font: {
        size: 14,
        weight: '600',
        lineHeight: '20px',
        family: 'Titillium Web'
      },
      maxTicksLimit: 4,
      padding: 10,
      callback: function (value: string | number) {
        if (typeof value === 'number' && value >= 1000) {
          value = `${value / 1000}k`
        }
        return buildLabel({value, suffix: '€'})
      }
    },
    grid: {
      display: false,
      drawOnChartArea: false,
      drawTicks: false
    }
  }

  if(!chartData) return null

  return (
    <>
      <div className="flex flex-col sm:flex-row w-full justify-between bg-kwota-neutral-100 rounded-2xl shadow-custom min-h-[193px]">
        <div className="p-6 min-w-fit">
          <h2 className="text-lg font-semibold leading-6 xl:text-xl text-kwota-neutral-800 font-titillium">
            Total earned revenue
          </h2>
          <span className="text-sm font-semibold text-kwota-neutral-600 font-titillium">
            Since {revenueChartData.year}
          </span>
          <h1 className="mt-6 text-xl xl:text-[28px] font-semibold leading-8 text-kwota-green-400 font-titillium">
            {revenueChartData.totalEarnedRevenue}€
          </h1>
        </div>
        <div className="flex items-end w-full h-full">
          <LineChart
            chartData={chartData}
            xAxisConfig={xAxis}
            yAxisConfig={yAxis}
            tooltipConfig={{suffix: '€'}}
            className="
            min-[1350px]:!min-w-[350px]
            min-[1408px]:!min-w-[400px]
            min-[1480px]:!min-w-[440px]
            2xl:!min-w-[498px]
            !max-h-[193px]
            font-titillium p-1 pb-4"
          />
        </div>
      </div>
    </>
  )
}

const PageContent = ({viewState, onClickTryAgain}: {
  viewState: InvoiceViewState;
  onClickTryAgain: () => void;
}) => {
  switch (viewState.type) {
    case APP_STATUS.DATA:
      return <RevenueChartContainer revenueChartData={viewState.data} />
    case APP_STATUS.ERROR:
      return <InternalServerError onClick={onClickTryAgain} />
    case APP_STATUS.LOADING:
    default:
      return <div className="min-h-[200px] flex justify-center items-center">
        <Spinner size="xl" />
      </div>
  }
}

export default RevenueChart
