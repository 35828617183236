import {Button, Icon} from '@kwota-cc/shared-components'

interface InternalServerErrorProps {
  text?: string;
  onClick?: () => void;
}
const InternalServerError = ({text = 'Something went wrong', onClick}: InternalServerErrorProps) => {
  const handleClick = () => {
    onClick?.()
  }
  return (
    <div className="w-full h-full min-h-[200px] flex items-center justify-center">
      <div className="max-w-sm mx-auto flex flex-col justify-center space-y-2">
        <div className="text-base text-kwota-neutral-800 flex space-x-1"><Icon icon="error" /><label>{text}</label></div>
        {onClick &&
          <Button text="Try again" onClick={handleClick} size="sm" btnType="secondary" />
        }
      </div>
    </div>
  )
}

export default InternalServerError
