import {useOnboardingContext} from '../../contexts/OnboardingContext'
import {CollapsedChecklist} from './components/CollapsedChecklist'
import {ExpandedChecklist} from './components/ExpandedChecklist'

export const Checklist = () => {
  const {collapsed, toggleCollapsed} = useOnboardingContext()

  switch (collapsed) {
    case true:
      return <CollapsedChecklist toggle={toggleCollapsed} />
    case false:
      return <ExpandedChecklist toggle={toggleCollapsed} />
  }
}
