import {Icon, Modal} from '@kwota-cc/shared-components'
import React, {ReactNode} from 'react'

import {PaymentStatus} from '../../types'
import {PaymentStatusPill} from './PaymentStatusPill'

interface InvoiceModalProps {
  title: string;
  paymentStatus?: PaymentStatus;
  children: ReactNode;
  success: boolean;
  visible: boolean;
  onClose: () => void;
}

const InvoiceModal = ({
  title,
  paymentStatus = PaymentStatus.WAITING_FOR_PAYMENT,
  children,
  success,
  visible,
  onClose
}: InvoiceModalProps) => {

  return (
    <Modal visible={visible} onClose={onClose}>
      <div className="min-w-[300px] sm:mx-auto sm:w-[552px] bg-kwota-neutral-100 pl-8 py-8 pr-4 shadow-custom rounded-2xl">
        <div className="flex justify-between w-full py-1 space-x-3">
          <div className="items-center justify-start w-full space-y-2 sm:flex sm:justify-between sm:space-y-0">
            <h2 className="text-left text-[28px] leading-8 font-titillium font-semibold text-kwota-neutral-800">
              {title}
            </h2>
            {success && <PaymentStatusPill status={paymentStatus} />}
          </div>
          <div className="flex items-center pr-4">
            <Icon icon="cross" size="md" onClick={onClose} />
          </div>
        </div>
        <div className="h-full pr-4 max-h-[540px] overflow-y-auto mt-4 sm:mt-8">
          {children}
        </div>
      </div>
    </Modal>
  )
}

export default InvoiceModal
